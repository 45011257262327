import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout navTab={0}>
    <SEO title="Home" />
    <h1>This is the Mission Control Database</h1>
    <p>Use the data on this page to assist astronauts.</p>
    <ul>
      <li key="link-1"><Link to="/browse-machines/">Browse Machines</Link></li>
      <li key="link-2"><Link to="/browse-parts/">Browse Parts</Link></li>
    </ul>
  </Layout>
)

export default IndexPage
